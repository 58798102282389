<template>
    <div :id="idVal" :class="classVal" role="alert">
        <span v-html="errorContent"></span>
    </div>
</template>

<script>
    export default {
        name: 'AlertBar',
        props: {
            errorContent: String,
            classVal: String,
            idVal: String
        },
    }
</script>

<style>
    .ic-alert {
        min-height: 3.75rem;
        display: flex;
        align-items: center;
    }
    .ic-alert-danger {
        color: rgba(139,0,0.7);
        background-color: rgba(240,22,21,0.24);
        border: 1px solid 1px solid rgba(139,0,0.85);
    }
    .ic-alert-warning {
        color: #d59e2e;
        background-color: rgba(240,147,21,0.24);
        border: 1px solid #d59e2e;
    }
    .ic-alert-notice {
        color: #abb7c3;
        background-color: #f0f4f8;
        border: 1px solid #c3cbd3;
    }
</style>