<template>
    <transition name="modal-fade">
        <div class="modal-backdrop">
            <div id="scan_modal" class="modal opacity-class" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription">
                <header class="modal-header">
                    <slot name="header">
                        <span class="content-header">{{ lang.scan_card }}</span>
                        <span class="guest-name not-shown"></span>

                        <button type="button" class="btn-close" @click="close" aria-label="Close modal">x</button>
                    </slot>
                </header>

                <section class="modal-body">
                    <slot name="body"> 
                        <Image srcVal="scan_card.gif" classVal="scan-card-img" idVal="" titleVal="" />
                    </slot>
                </section>

                <footer class="modal-footer">
                    <slot name="footer">
                        <!--<button type="button" id="close-alert" class="btn ic-btn-small ic-btn-green" @click="setHotelAndArea()" aria-label="Store">{{ lang.continue }}</button>-->
                        <Loader idVal="loader-scan-card" classVal="" />
                        <template v-if="insert_dev_components == 'true'">
                            <span id="skip_text" class="not-shown" @click="skipGuests()">{{ lang.skip }}</span>
                        </template>
                        <span id="success_text">{{ lang.card_associated }}</span>
                    </slot>
                </footer> 
            </div>
        </div>
    </transition>
</template>

<script>
    import $ from 'jquery'
    import * as Utility from '../../js/utility.js'
    import { useGettext } from '@jshmrtn/vue3-gettext'

    import Loader from './../elements/Loader.vue'
    import Image from './../elements/Image.vue'

    export default {
        name: "ScanModal",
        components: {
            Loader,
            Image
        },
        data() {
            return {
                lang: "",
                insert_dev_components: process.env.VUE_APP_INSERT_DEV_COMPONENTS,
            }
        },
        setup() { 
            const language = useGettext();
            return {
                language,
            };
        },
        mounted() {  
            this.lang = Utility.getLanguage(this.language);
        },
        methods: {
            close() {
                setTimeout(()=> {
                    $("#scan_modal span.content-header").removeClass("not-shown");
                    $("#scan_modal span.guest-name").addClass("not-shown");
                    $("#skip_text").addClass("not-shown");
                }, 500);
                
                this.$emit('close');
            },
            prepareScanForUsers() {
                $("#scan_modal span.content-header").addClass("not-shown");
                $("#scan_modal span.guest-name").removeClass("not-shown");
                $("#skip_text").removeClass("not-shown");
            },
            skipGuests() {
                this.$emit('close');
                setTimeout(()=> {
                    this.$parent.nextGuest();
                }, 450);
            }
        }
    }
</script>

<style>
    /* transition modal */
    .modal-fade-enter, .modal-fade-enter-active,
    .modal-fade-leave-active {
        opacity: 0;
    }
    .modal-fade-enter-to, .modal-fade-leave {
        opacity: 1;
    }

    .modal-fade-enter-active,
    .modal-fade-leave-active {
        transition: opacity .5s ease
    }
    .modal-backdrop {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .modal {
        background: #FFFFFF;
        box-shadow: 2px 2px 20px 1px;
        overflow-x: auto;
        display: flex;
        flex-direction: column;
    }
    .modal-header,
    .modal-footer {
        padding: 15px;
        display: flex;
    }
    .modal-header {
        border-bottom: 1px solid #eeeeee;
        color: #4AAE9B;
        justify-content: space-between;
    }
    .modal-footer {
        border-top: 1px solid #eeeeee;
        justify-content: flex-end;
    }
    .modal-body {
        position: relative;
        padding: 20px 10px;
        overflow: auto;
    }
    .btn-close {
        border: none;
        font-size: 20px;
        padding: 20px;
        cursor: pointer;
        font-weight: bold;
        color: #4AAE9B;
        background: transparent;
    }
    .btn-green {
        color: white;
        background: #4AAE9B;
        border: 1px solid #4AAE9B;
        border-radius: 2px;
    }
    .modal-header .btn-close {
        padding: 0px;
        margin-top: 5px;
        margin-right: 15px;
    }
    .modal-header .btn-close:active{
        position: relative;
        color: #248767;
        top: 1px;
    }
    .modal-header button.btn-close:focus {
        outline: none;
    }
    span.content-header, span.guest-name {
        font-size: 23px;
        position: relative;
        top: 5px;
    }

    #scan_modal {
        width: 300px;
        max-height: 90%; 
        position: unset;        
        transition: width 1s, height 1s;
    }
    #scan_modal header.modal-header {
        border-bottom: 0px;
    }
    #scan_modal span.content-header, #scan_modal span.guest-name {
        width: 100%;
        text-align: center;
    }    
    img.scan-card-img {
        width: 100%;
        opacity: 0.7;
    }
    #scan_modal .modal-footer {
        flex-direction: column;
        justify-content: center !important;
        border-top: none;
        margin-top: 10px;
        margin-bottom: 20px;
    }
    #skip_text {
        cursor: pointer;
        margin-top: 40px;
        margin-left: 0px;
        color: rgba(52,52,52,.8);
        margin-bottom: 0px;
        text-decoration: underline;
    }
    #skip_text:active {
        position: relative;
        top: 1px;
    }
    #loader-scan-card {
        display: flex;
        width: 50px;
    }
    #success_text {
        color: #248767;
        display: none;
    }

    @media screen and (max-width: 350px){
        #scan_modal {
            width: 90%;
        }
    }
</style>