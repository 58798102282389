<template>
    <transition name="modal-fade">
        <div class="modal-backdrop">
            <div id="create-edit-modal" :class="'modal opacity-class '+checkForLargeView()" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription">
                <header class="modal-header">
                    <slot name="header">
                        <span class="content-header">
                            <span v-if="content == 'create_card'">{{ lang.create_card }}</span>
                            <span v-if="content == 'edit_card'">{{ lang.edit_card }}</span>
                            <span v-if="content == 'create_service_type'">{{ lang.new_service_type }}</span>
                            <span v-if="content == 'view_service_type'">{{ lang.view_service_type }}</span>
                            <span v-if="content == 'view_area'">{{ lang.area_details }}</span>
                            <span v-if="content == 'create_area'">{{ lang.new_area }}</span>
                            <span v-if="content == 'create_guest'">{{ lang.create_guest }}</span>
                            <span v-if="content == 'edit_guest' || content == 'edit_partner'">{{ lang.edit_guest }}</span>
                            <span v-if="content == 'view_wallet'">{{ lang.account_n }}</span>
                            <span v-if="content == 'edit_service'">{{ lang.edit_service }}</span>
                            <span v-if="content == 'customer_cluster'">{{ lang.set_cluster }}</span>
                            <span v-if="content == 'edit_cluster'">{{ lang.edit_cluster }}</span>
                            <span v-if="content == 'create_cluster'">{{ lang.create_cluster }}</span>
                            <span v-if="content == 'show_cluster'">{{ lang.cluster_detail }}</span>
                            <span v-if="content == 'customer_card'">{{ lang.create_card }}</span>
                            <span v-if="content == 'migrate_wallet'">{{ lang.migrate_wallet }}</span>
                            <span v-if="content == 'migrate_card'">{{ lang.migrate_card }}</span>
                            <span v-if="content == 'migrate_points'">{{ lang.migrate_points }}</span>
                            <span v-if="content == 'counter'">{{ lang.edit_counter }}</span>
                        </span>

                        <button type="button" class="btn-close" @click="close" aria-label="Close modal">x</button>
                    </slot>
                </header>

                <section :class="'modal-body '+checkCustomClass()">
                    <slot name="body">
                        <template v-if="content == 'create_card' || content == 'edit_card'">
                            <div class="wrap-active-checkbox">
                                <label class="ic-switch ic-switch-small">
                                    <input type="checkbox" class="sr-only" id="card_active" checked>
                                    <span class="ic-switch-slider rotate-elment"></span>
                                </label>
                                <span class="lbl-content">{{ lang.active_card }}</span>
                            </div>

                            <input type="text" class="form-control ic-input" id="card_numb" :placeholder="lang.card_numb_simply" autocomplete="off">
                            <input type="text" class="form-control ic-input" id="cust_name" :placeholder="lang.cust_name" autocomplete="off">
                            <input type="text" class="form-control ic-input" id="cust_surname" :placeholder="lang.cust_surname" autocomplete="off">

                            <div class="wrap-select">
                                <select id="wallet_type" class="form-control ic-input" @click="setSelectColor()">
                                    <option selected disabled>{{ lang.wallet_type }}</option>
                                    <option v-for="(wallet,i) in wallet_types" :key="i" :value='wallet.id'>{{ wallet.name }}</option>
                                </select>

                                <i class="ri-arrow-down-s-line" for="wallet_type"></i>
                            </div>
                            <br/>

                            <p class="data-error">{{ lang.error_card }}</p>
                        </template>

                        <template v-if="content == 'view_service_type' || content == 'create_service_type'">
                            <input type="text" class="form-control ic-input" id="st_name" :placeholder="lang.name" :readonly="checkForReadOnly()" autocomplete="off">
                            <input type="text" class="form-control ic-input" id="st_code" placeholder="Code" :readonly="checkForReadOnly()" autocomplete="off">
                            <input type="text" class="form-control ic-input" id="st_ext_code" placeholder="Ext. Code" :readonly="checkForReadOnly()" autocomplete="off">

                            <template v-if="content == 'view_service_type'">
                                <p class='grey-clr space-top'>{{ lang.rel_services }}</p>
                                <template v-for="(service,x) in $parent.list_services.shops" :key="x">       
                                    <div v-if="checkToShowServices(service.id)" class="ic-tag label"> 
                                        {{ service.name }}
                                    </div>
                                </template>
                            </template>

                            <p class="data-error">{{ lang.error_store_ts }}</p>
                        </template>

                        <template v-if="content == 'view_area' || content == 'create_area'">
                            <input type="text" class="form-control ic-input" id="a_name" :placeholder="lang.name" :readonly="checkForReadOnly()" autocomplete="off">
                            <input type="text" class="form-control ic-input" id="a_code" placeholder="Code" :readonly="checkForReadOnly()" autocomplete="off">
                            <input type="text" class="form-control ic-input" id="a_ext_code" placeholder="Ext. Code" :readonly="checkForReadOnly()" autocomplete="off">

                            <p class="data-error">{{ lang.error_store_a }}</p>
                        </template>

                        <template v-if="content == 'create_guest' || content == 'edit_guest' || content == 'edit_partner'">

                            <p v-if="content == 'create_guest'" class='grey-clr guest less-bottom'>
                                <i class="ri-user-search-line"></i>
                                {{ lang.find_autofill }}:
                            </p>

                            <div v-if="content == 'create_guest'" class="wrap-searcher">
                                <input type="hidden" class="form-control ic-input" id="guest_id_">
                                <input type="text" class="form-control ic-input" list="" id="search_guest_" @change="setIcon()" @input="checkForList()" :placeholder="lang.search_guest" autocomplete="off">
                                <datalist id="partner_list">
                                    <template v-for="(partner,i) in partner_list" :key="i">
                                        <option :data-value="partner.partner_id.id">{{ partner.partner_id.name }}</option>
                                    </template>
                                </datalist>
                                <i id="loader_guest" class="ri-loader-line rotate"></i>
                                <i id="search_guest" class="ri-search-line"></i>
                            </div>

                            <p class="grey-clr clear" @click="clearGuest()">{{ lang.clear }}</p>

                            <p v-if="content == 'create_guest'" class='grey-clr guest'>
                                <i class="ri-user-add-line"></i>
                                {{ lang.create_guest }}:
                            </p>
                            
                            <div v-if="content == 'create_guest' || content == 'edit_guest'" class="checkbox-elment">
                                <label class="ic-switch ic-switch-small">
                                    <input type="checkbox" class="sr-only" id="guest_main">
                                    <span class="ic-switch-slider rotate-elment"></span>
                                </label>
                                <span class="content">{{ lang.main }}</span>
                            </div>

                            <div v-if="content == 'create_guest' || content == 'edit_guest'" class="checkbox-elment">
                                <label class="ic-switch ic-switch-small">
                                    <input type="checkbox" class="sr-only" id="guest_child">
                                    <span class="ic-switch-slider rotate-elment"></span>
                                </label>
                                <span class="content">{{ lang.child_qty }}</span>
                            </div>

                            <div v-if="content == 'create_guest' || content == 'edit_guest'" class="checkbox-elment">
                                <label class="ic-switch ic-switch-small">
                                    <input type="checkbox" class="sr-only" id="guest_debit">
                                    <span class="ic-switch-slider rotate-elment"></span>
                                </label>
                                <span class="content">{{ lang.debit }}</span>
                            </div>

                            <div v-if="content == 'create_guest' || content == 'edit_guest'" class="checkbox-elment">
                                <label class="ic-switch ic-switch-small">
                                    <input type="checkbox" class="sr-only" id="guest_totem">
                                    <span class="ic-switch-slider rotate-elment"></span>
                                </label>
                                <span class="content">{{ lang.can_use_totem }}</span>
                            </div>

                            <br/>
                            <input type="text" class="form-control ic-input" id="guest_name_" :placeholder="lang.guest_name" autocomplete="chrome-off">
                            <input type="text" class="form-control ic-input" id="guest_email_" placeholder="Email" autocomplete="chrome-off">
                            <input type="text" class="form-control ic-input" id="guest_phone_" :placeholder="lang.phone" autocomplete="chrome-off">
                            <p class='grey-clr guest space-top no-bott'>{{ lang.discharge_day }}</p>
                            <input type="date" class="form-control ic-input" id="guest_withdraw_" autocomplete="chrome-off">

                            <p class="data-error">{{ lang.error_store_guest }}</p>
                        </template>

                        <template v-if="content == 'view_wallet'">
                            <p class='grey-clr guest nobott'>{{ lang.guest_name}}</p>
                            <input type="text" class="form-control ic-input wallet" id="wallet_cust_name_" readonly>

                            <p class='grey-clr guest nobott'>Email</p>
                            <input type="text" class="form-control ic-input wallet" id="wallet_cust_mail_" readonly>

                            <p class='grey-clr guest nobott'>{{ lang.phone }}</p>
                            <input type="text" class="form-control ic-input wallet" id="wallet_cust_phone_" readonly>

                            <p class="grey-clr guest space-top nobott title-content">{{ lang.info_account }}</p>
                            <p class="grey-clr guest data-p" id='account_n_'></p>

                            <p class="grey-clr guest nobott title-content">{{ lang.balance }}</p>
                            <p class="grey-clr guest data-p" id='balance_n_'></p>
                        </template>

                        <template v-if="content == 'edit_service' && start_render">
                            <p class='grey-clr guest nobott'>{{ lang.area}}</p>
                            <DropDownList idVal="area_" nameVal="" classVal="iconic-select" showDefault="false" :val="content_services.area_id.id.toString()" :data="list_area" useChangeFunction="true" /> 

                            <p class='grey-clr guest nobott'>{{ lang.service }}</p>
                            <DropDownList idVal="service_" nameVal="" classVal="iconic-select" showDefault="false" :val="content_services.service_id.id.toString()" :data="list_services" useChangeFunction="true" />     

                            <p class='grey-clr guest nobott'>{{ lang.customer }}</p>
                            <DropDownList idVal="guest_" nameVal="" classVal="iconic-select" showDefault="false" :val="content_services.reservation_partner_id.id.toString()" :data="list_guests" useChangeFunction="true" />     

                            <p class='grey-clr guest nobott'>{{ lang.date_cell }}</p>
                            <input type="date" id="date_" class="iconic-select" :value="content_services.date" />

                            <input type="hidden" id="srv_line_hid" :value="content_services.id"/>
                        </template>

                        <template v-if="content == 'customer_cluster'">
                            <Loader v-if="isLoading" idVal="" classVal="loader-cluster-info" />
                            
                            <p class='grey-clr guest less-bottom cluster'>
                                {{ lang.find_or_new }}
                            </p>

                            <div class="wrap-searcher">
                                <input type="hidden" class="form-control ic-input" id="cluster_id_">
                                <input type="text" class="form-control ic-input" list="" id="search_cluster_" @change="setIconCluster()" @input="checkForListCluster()" :placeholder="lang.search_cluster" autocomplete="off">
                                <datalist id="cluster_list">
                                    <template v-for="(cluster,i) in cluster_list" :key="i">
                                        <option :data-value="cluster.ClusterID">{{ cluster.ClusterName }}</option>
                                    </template>
                                </datalist>
                                <i id="loader_cluster" class="ri-loader-line rotate"></i>
                                <i id="search_cluster" class="ri-search-line"></i>
                            </div>

                            <template v-if="customerCluster.length > 0">
                                <p class='grey-clr guest space-top'>{{ lang.cust_cluster }}</p>

                                <div class="wrap-cluster">
                                    <template v-for="(cluster,x) in customerCluster" :key="x">       
                                        <div class="ic-tag label"> 
                                            <span class='cluster_name'>{{ getClusterName(cluster) }}</span>
                                            <button class="btn" role="button" @click="removeFromCluster(cluster)"><span class="sr-only">Remove tag</span></button> 
                                        </div>
                                    </template>
                                </div>
                            </template>
                        </template>

                        <template v-if="content == 'edit_cluster' || content == 'create_cluster' || content == 'show_cluster'">
                            <Loader v-if="isLoading" idVal="" classVal="loader-cluster-info" />

                            <p v-if="content == 'edit_cluster' || content == 'show_cluster'" class='grey-clr guest nobott'>Cluster ID</p>
                            <input v-if="content == 'edit_cluster' || content == 'show_cluster'" type="text" class="form-control ic-input" id="cluster_id_" placeholder="ID" disabled autocomplete="off">
                            
                            <p class='grey-clr guest nobott'>{{ lang.name }}</p>
                            <input type="text" class="form-control ic-input" id="cluster_name_" :placeholder="lang.name" :readonly="content == 'show_cluster'" @input="alphanumeric" autocomplete="off">
                            
                            <p class='grey-clr guest nobott'>{{ lang.booking }}</p>
                            <input type="text" class="form-control ic-input" id="cluster_code_" :placeholder="lang.booking" :readonly="content == 'show_cluster'" autocomplete="off">
                            
                            <p class='grey-clr guest nobott'>{{ lang.description }}</p>
                            <textarea class="form-control ic-input" id="cluster_description_" :placeholder="lang.description" :readonly="content == 'show_cluster'" autocomplete="off"></textarea>

                            <br/>
                            <p class="data-error">{{ lang.error_store_cluster }}</p>
                        </template>

                        <template v-if="content == 'customer_card'">
                            <Loader v-if="isLoadingCard" idVal="" classVal="loader-card-info" />
                            <br>
                            <label class="ic-switch ic-switch-small">
                                <input type="checkbox" class="sr-only" id="cust_active_card">
                                <span class="ic-switch-slider rotate-elment"></span>
                            </label>
                            <span class="content chkbox-lbl">{{ lang.active_card }}</span>
                            <br>
                            <br>

                            <p class='grey-clr guest nobott'>{{ lang.card_numb_simply }}</p>
                            <input type="text" class="form-control ic-input" id="cust_card_numb" :placeholder="lang.card_numb_simply" autocomplete="off">                         
                            <br>

                            <p class='grey-clr guest nobott'>{{ lang.select_card_type }}</p>
                            <div class="wrap-select card">
                                <select id="cust_card_type" class="form-control ic-input" @click="setSelectColor()">
                                    <option selected disabled>{{ lang.card_type }}</option>
                                    <option v-for="(card,i) in card_type" :key="i" :value='card[0].id'>{{ card[0].name }}</option>
                                </select>

                                <i class="ri-arrow-down-s-line" for="card_type"></i>
                            </div>
                            <br>
                        </template>

                        <template v-if="content == 'migrate_wallet'">
                            <Loader v-if="isSearchingCard" idVal="loader-cards" classVal="" />

                            <p class='grey-clr guest'>{{ lang.migrate_wallet_txt }}</p>

                            <p class='grey-clr guest nobott migrate'>{{ lang.wallet_to_deactivate }}</p>
                            <input type="text" class="form-control ic-input migrate" id="wallet_to_deactivate" :placeholder="lang.wallet_to_deactivate" readonly="true" autocomplete="off">
                            
                            <p class='grey-clr guest nobott migrate'>{{ lang.new_wallet }}</p>
                            <input type="text" class="form-control ic-input migrate" id="new_wallet" :placeholder="lang.new_wallet" autocomplete="off">

                            <button type="button" @click="scanCard('wallet')" class="btn ic-btn-small ic-btn-outline-grey btn-add-line"> {{ lang.scan_card }}</button>
                        </template>

                        <template v-if="content == 'migrate_card'">
                            <Loader v-if="isSearchingCard" idVal="loader-cards" classVal="" />

                            <p class='grey-clr guest'>{{ lang.migrate_card_txt }}</p>

                            <p class='grey-clr guest nobott migrate'>{{ lang.card_to_deactivate }}</p>
                            <input type="text" class="form-control ic-input migrate" id="card_to_deactivate" :placeholder="lang.card_to_deactivate" readonly="true" autocomplete="off">
                            
                            <p class='grey-clr guest nobott migrate'>{{ lang.new_card }}</p>
                            <input type="text" class="form-control ic-input migrate" id="new_card" :placeholder="lang.new_card" autocomplete="off">

                            <button type="button" @click="scanCard('card')" class="btn ic-btn-small ic-btn-outline-grey btn-add-line"> {{ lang.scan_card }}</button>
                        </template>

                        <template v-if="content == 'migrate_points'">
                            <Loader v-if="isMigratingPoints" idVal="loader-cards" classVal="" />

                            <p class='grey-clr guest'>{{ lang.migrate_points_txt }}</p>

                            <p class='grey-clr guest nobott migrate'>{{ lang.user_from_migrate }}</p>
                            <!--<input type="hidden" id="tot_points" />-->
                            <!--<input type="hidden" id="counter_id" />-->
                            <input type="hidden" id="from_user_id" />
                            <input type="text" class="form-control ic-input migrate" id="user_from_migrate" :placeholder="lang.user_from_migrate" readonly="true" autocomplete="off">
                            
                            <p class='grey-clr guest nobott migrate'>{{ lang.user_to_migrate }}</p>
                            <div class="wrap-searcher migrate">
                                <input type="hidden" class="form-control ic-input" id="cust_id_">
                                <input type="text" class="form-control ic-input migrate" list="" id="search_cust_" @change="setIcon2()" @input="checkForListCustRoungick()" :placeholder="lang.search_guest" autocomplete="off">
                                <datalist id="cust_list">
                                    <template v-for="(partner,i) in user_list" :key="i">
                                        <option :data-value="partner.id" :value="partner.email">{{ partner.name }}</option> <!-- @click="setID(partner.id)" -->
                                    </template>
                                </datalist>
                                <i id="loader_cust" class="ri-loader-line rotate"></i>
                                <i id="search_cust" class="ri-search-line"></i>
                            </div>
                        </template>

                        <template v-if="content == 'counter'">
                            <input type="hidden" id="counter_id_partner"/>
                            <input type="hidden" id="counter_id"/>

                            <p class='grey-clr'>{{ lang.counter_name }}</p>
                            <input type="text" class="form-control ic-input migrate" id="counter_name" :placeholder="lang.counter_name" disabled autocomplete="off">

                            <p class='grey-clr'>{{ lang.set_points }}</p>
                            <input type="number" min="0" class="form-control ic-input migrate" id="counter_points" :placeholder="lang.set_points" autocomplete="off">
                        </template>
                    </slot>
                </section>

                <footer class="modal-footer">
                    <slot name="footer">
                        <template v-if="content != 'view_service_type' && content != 'view_area' && content != 'view_wallet' && content != 'show_cluster'">
                            <button type="button" id="btn-filter" class="btn ic-btn-small ic-btn-green" @click="saveFunction">
                                <template v-if="content == 'customer_cluster'">
                                    {{ lang.add }}
                                </template>
                                <template v-else>
                                    {{ lang.save }}
                                </template>
                            </button>
                        </template>
                        <button type="button" class="btn ic-btn-small ic-btn-green" @click="close" aria-label="Close modal">{{ lang.close }}</button>
                    </slot>
                </footer>
            </div>
        </div>
    </transition>    
</template>

<script>
    import $ from 'jquery'
    import axios from 'axios'
    import * as Utility from '../../js/utility.js'
    import { useGettext } from '@jshmrtn/vue3-gettext'

    import DropDownList from './../elements/DropDownList.vue'
    import Loader from '../elements/Loader.vue'

    export default {
        name: 'CreatEditContent',
        components: {
            DropDownList,
            Loader
        },
        props: {
            list_area: [],
            list_guests: [],
            content: String,
            partner_list: [],
            cluster_list: [],
            wallet_types: [],
            list_services: [],
            content_services: [],
            saveFunction: String,
        },
        data() {
            return {
                lang: "",
                user_list: [],
                card_type: [],
                isLoading: true,
                wallet_label: "",
                start_render: false,
                customerCluster: [],
                isLoadingCard: false,
                isSearchingCard: false,
                isMigratingPoints: false,
                service_type_services: [],
            }
        },
        setup() { 
            const language = useGettext();
            return {
               language,
            };
        },
        mounted() {  
            this.lang = Utility.getLanguage(this.language);
            this.wallet_label = this.lang.wallet_type;
        },
        methods: {
            close() {
                this.$emit('close');
            },
            checkCustomClass() {
                if(this.content == 'view_wallet'){ 
                    return 'wallet-padd';
                }else{ 
                    return '';
                }
            },
            setSelectColor() {
                $("#wallet_type").css("color", "#495057");
            },
            alphanumeric() { 
                var letters = /^[0-9a-zA-Z]+$/; console.log($("#cluster_name_").val());
                if($("#cluster_name_").val().match(letters)){
                    this.setClusterCode();
                    return;
                } else {
                    let v = $("#cluster_name_").val();
                    $("#cluster_name_").val(v.substring(0, (v.length - 1)));
                    return;
                }
            },
            getClusterName(cluster_id) {
                for(let i=0; i<this.cluster_list.length; i++) {
                    if(this.cluster_list[i].ClusterID == cluster_id) {
                        return this.cluster_list[i].ClusterName;
                    }
                }
            },
            setClusterContent(response) {
                this.customerCluster = Object.keys(response.data.customer.customerCluster);
                this.isLoading = false;
            },
            setClusterContentForEdit(cluster) {
                $("#cluster_id_").val(cluster.ClusterID);
                $("#cluster_code_").val(cluster.ClusterCode);
                $("#cluster_name_").val(cluster.ClusterName);
                $("#cluster_description_").val(cluster.ClusterDescription);

                this.isLoading = false;
            },
            setClusterContentForViews(cluster) {
                $("#cluster_id_").val(cluster.id);
                $("#cluster_code_").val(cluster.code);
                $("#cluster_name_").val(cluster.name);
                $("#cluster_description_").val(this.$parent.checkDescription(cluster.description));

                this.isLoading = false;
            },
            setClusterCode() {
                /*if(this.content == 'edit_cluster') { return; }*/
                $("#cluster_code_").val(Utility.slugify($("#cluster_name_").val()));
            },
            removeFromCluster(cluster_id) {
                let url = "";
                if(process.env.VUE_APP_ROUNGICK_FORMAT_URL == 'new'){
                    url = process.env.VUE_APP_ROUNGICK_API+localStorage.getItem("tenant")+'/customers/'+this.$parent.content_guest_id+'/clusters';
                }else{
                    url = process.env.VUE_APP_ROUNGICK_API+'customers/'+this.$parent.content_guest_id+'/clusters';
                }

                let post_data = {clusters: {}};
                post_data.clusters[cluster_id] = false;
                axios.post(url, post_data)
                .then(() => { 
                    for(let i=0; i<this.customerCluster.length; i++) {
                        if(this.customerCluster[i] == cluster_id){
                            this.customerCluster.splice(i, 1);
                        }
                    }
                }).catch(err => { 
                    this.manageRequestException(err);
                    this.title_alert_modal = this.lang.alert;
                    this.content_alert_modal = this.lang.storage_error;
                    this.isAlertModalVisible = true;  
                });                
            },
            setCardContent(card) { 
                if(card.state == "Active"){
                    document.getElementById('card_active').checked = true;
                }else{
                    document.getElementById('card_active').checked = false;
                }

                $("#card_numb").val(card.number);
                $("#cust_name").val(card.customer_name);
                $("#cust_surname").val(card.customer_surname); 
                setTimeout(()=>{
                    this.setSelectColor();
                    $("#wallet_type :nth-child("+(parseInt(card.wallet_type)+1)+")").prop('selected', true);                
                }, 350);
            },
            setTransaction(transaction) {
                setTimeout(()=>{ 
                    $("#wallet_cust_name_").val(transaction.account_id.partner_id.name);
                    $("#wallet_cust_mail_").val(transaction.account_id.partner_id.email);
                    $("#wallet_cust_phone_").val(transaction.account_id.partner_id.phone);

                    $("#account_n_").text(transaction.account_id.account_number);
                    $("#balance_n_").text(transaction.account_id.balance);
                    //$("#balance_n_").text(transaction.balance);
                }, 300);
            },
            setGuestContent(guest) {
                if(guest.main){
                    document.getElementById('guest_main').checked = true;
                }else{
                    document.getElementById('guest_main').checked = false;
                }

                if(guest.children){
                    document.getElementById('guest_child').checked = true;
                }else{
                    document.getElementById('guest_child').checked = false;
                }

                if(guest.debit){
                    document.getElementById('guest_debit').checked = true;
                }else{
                    document.getElementById('guest_debit').checked = false;
                }

                if(guest.can_use_totem){
                    document.getElementById('guest_totem').checked = true;
                }else{
                    document.getElementById('guest_totem').checked = false;
                }

                $("#guest_name_").val(guest.partner_id.name);
                $("#guest_email_").val(guest.partner_id.mail);
                $("#guest_phone_").val(guest.partner_id.phone); 
                $("#guest_withdraw_").val(guest.discharge_date); 
            },
            setPartnerContent(cust) {
                $("#guest_name_").val(cust.name);
                $("#guest_email_").val(cust.email);
                $("#guest_phone_").val(cust.phone); 
            },
            setServiceTypeContent(service_type){ 
                setTimeout(()=>{
                    $("#st_name").val(service_type.name);
                    $("#st_code").val(service_type.code);
                    $("#st_ext_code").val(service_type.external_code);
                }, 250);

                this.service_type_services = service_type.service_ids;
            },
            setAreaContent(area) {
                setTimeout(()=>{
                    $("#a_name").val(area.name);
                    $("#a_code").val(area.code);
                    $("#a_ext_code").val(area.external_code);
                }, 250);
            },
            checkForLargeView() {
                if(this.content == 'create_guest' || this.content == 'edit_guest' || this.content == 'edit_partner' || this.content == 'view_wallet' || this.content == 'customer_cluster' || this.content == 'edit_cluster' || this.content == 'create_cluster'){ 
                    return 'guest-width';
                }else if(this.content == 'migrate_wallet' || this.content == 'migrate_card' || this.content == 'migrate_points'){
                    return 'migrate-width';
                }else{
                    return '';
                }
            },
            checkToShowServices(service_id) { 
                for(let i=0; i<this.service_type_services.length; i++) {
                    let curr_s = this.service_type_services[i];
                    if(curr_s.id == service_id) {
                        return true;
                    }else{
                        continue;
                    }
                }

                return false;
            },
            clearFields() {
                $("#st_name").val("");
                $("#st_code").val("");
                $("#st_ext_code").val("");

                $("#a_name").val("");
                $("#a_code").val("");
                $("#a_ext_code").val("");

                $("#guest_name_").val("");
                $("#guest_email_").val("");
                $("#guest_phone_").val("");
                if(this.content == 'create_guest' || this.content == 'edit_guest') { 
                    document.getElementById("guest_main").checked = false;
                    document.getElementById("guest_child").checked = false;
                }
            },
            checkForReadOnly() {
                if(this.content.includes("view_")){
                    return true;
                }else{
                    return false;
                }
            },
            checkForList() { 
                let input = $("#search_guest_").val();
                let options = $('#partner_list option');

                if(input.length == 0){
                    $("#loader_guest").toggle(false);
                    $("#search_guest").toggle(true);
                }

                if(input.length > 0){                       
                    if(input.length > 2){
                        if(options.length == 0){
                            let params = "page_size=9999&filter=[\"name\", \"ilike\", \""+ input +"\"]";
                            axios.get(process.env.VUE_APP_ENDPOINT_HOTELS_URL+localStorage.getItem("tenant")+'/partner?'+params).then(response => {                       
                                return this.$parent.managePartnerResponse(response);
                            }).catch(err => { 
                                return this.$parent.manageRequestException(err);
                            });
                        }else{
                            $("#search_guest_").attr("list", "partner_list");
                        }
                    }else{
                        $("#search_guest_").attr("list", "");
                        this.$parent.partner_list = [];
                    }

                    $("#search_guest").fadeOut();
                    setTimeout(()=>{
                        $("#loader_guest").fadeIn();
                    }, 300);
                }

                for(var i = 0; i < options.length; i++) {
                    var option = options[i];

                    if(option.innerText === input) {
                        return this.setDataToGuest(option.getAttribute('data-value'));
                    }
                }
            },
            checkForListCluster() {
                let input = $("#search_cluster_").val();

                if(input.length == 0){
                    $("#loader_cluster").toggle(false);
                    $("#search_cluster").toggle(true);
                }

                if(input.length > 0){                       
                    if(input.length > 2){
                        $("#search_cluster_").attr("list", "cluster_list");
                    }else{
                        $("#search_cluster_").attr("list", "");
                    }

                    $("#search_cluster").fadeOut();
                    setTimeout(()=>{
                        $("#loader_cluster").fadeIn();
                    }, 300);
                }

                let options = $('#cluster_list option');

                for(var i = 0; i < options.length; i++) {
                    var option = options[i];

                    if(option.innerText.toLowerCase() === input.toLowerCase()) {
                        $("#search_cluster_").blur();
                        setTimeout(()=>{
                            $("#loader_cluster").toggle(false);
                            $("#search_cluster").toggle(true);
                        }, 500);

                        return $("#cluster_id_").val(option.getAttribute('data-value'));
                    }
                }

                $("#cluster_id_").val('none');
            },
            setIcon() {
                $("#loader_guest").fadeOut();
                setTimeout(()=>{
                    $("#search_guest").fadeIn();
                }, 300);
            },
            setIconCluster() {
                $("#loader_cluster").fadeOut();
                setTimeout(()=>{ 
                    $("#search_cluster").fadeIn();
                }, 300);
            },
            setDataToGuest(guest_id) {
                $("#search_guest_").blur();
                setTimeout(()=>{
                    $("#loader_guest").toggle(false);
                    $("#search_guest").toggle(true);
                }, 500);
                
                for(let i=0; i<this.partner_list.length; i++) {
                    let p = this.partner_list[i];

                    if(p.partner_id.id == parseInt(guest_id)){ 
                        $("#guest_id_").val(p.partner_id.id);
                        $("#guest_name_").val(p.partner_id.name);
                        $("#guest_email_").val(p.partner_id.mail);
                        $("#guest_phone_").val(p.partner_id.phone);

                        $("#guest_name_").prop("readonly", true);
                        $("#guest_email_").prop("readonly", true);
                        $("#guest_phone_").prop("readonly", true);

                        $("p.grey-clr.clear").fadeIn();

                        setTimeout(()=>{
                            $("#loader_guest").toggle(false);
                            $("#search_guest").toggle(true);
                        }, 500);

                        return;
                    }
                }
            },
            clearGuest() {
                $(".data-error").fadeOut();
                $("p.grey-clr.clear").fadeOut();

                $("#search_guest_").val("");
                $("#search_guest_").attr("list", "");
                
                $("#guest_id_").val("");
                $("#guest_name_").val("");
                $("#guest_email_").val("");
                $("#guest_phone_").val("");
                $("#guest_name_").prop("readonly", false);
                $("#guest_email_").prop("readonly", false);
                $("#guest_phone_").prop("readonly", false);
                document.getElementById("guest_main").checked = false;
                document.getElementById("guest_child").checked = false;
            },
            scanCard(content) { 
                this.$parent.isScanModalVisible = true;

                let input = "";
                if(content == 'card'){
                    input = "#new_card";
                }else{
                    input = "#new_wallet";
                }

                let self = this;
                $(input).focus();
                $(input).change(function() {
                    $(this).blur();
                    self.isSearchingCard = true;
                    self.$parent.isScanModalVisible = false;
                    setTimeout(()=>{
                        $(this).off('change');
                        self.saveFunction();
                    },1000);
                });
            },
            checkForListCustRoungick() {
                let input = $("#search_cust_").val();
                let options = $('#cust_list option');

                if(input.length == 0){
                    $("#loader_cust").toggle(false);
                    $("#search_cust").toggle(true);
                }

                if(input.length > 0){                       
                    if(input.length > 2){
                        if(options.length == 0){
                            let params = "&page_size=9999&filter=[\"user_ids\", \"!=\", false], [\"active\",\"=\", true],[\"email\", \"ilike\", \""+ input +"\"]";
                            axios.get(process.env.VUE_APP_ENDPOINT_URL+localStorage.getItem("tenant")+"/res.partner/search?fields={id, name, email}"+params).then(response => {
                                this.user_list = response.data.data.result;
                                $("#search_cust_").attr("list", "cust_list");

                                return this.start_search = false;                 
                                
                            }).catch(err => { 
                                return this.$parent.manageRequestException(err);
                            });
                        }else{
                            $("#search_cust_").attr("list", "cust_list");
                        }
                    }else{
                        $("#search_cust_").attr("list", "");
                        this.user_list = [];
                    }

                    $("#search_cust").fadeOut();
                    setTimeout(()=>{
                        $("#loader_cust").fadeIn();
                    }, 300);
                }

                for(var i = 0; i < options.length; i++) {
                    var option = options[i];

                    if(option.value === input) { 
                        $("#cust_id_").val(option.getAttribute('data-value'));
                        return setTimeout(()=> { this.setIcon2(); }, 300);
                    }
                }
            },
            setIcon2() {
                $("#loader_cust").fadeOut();
                setTimeout(()=>{
                    $("#search_cust").fadeIn();
                }, 300);
            },
        }
    }
</script>

<style>
    .modal-fade-enter, .modal-fade-enter-active,
    .modal-fade-leave-active {
        opacity: 0;
    }
    .modal-fade-enter-to, .modal-fade-leave {
        opacity: 1;
    }

    .modal-fade-enter-active,
    .modal-fade-leave-active {
        transition: opacity .5s ease
    }
    .modal-backdrop {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .modal {
        background: #FFFFFF;
        box-shadow: 2px 2px 20px 1px;
        overflow-x: auto;
        display: flex;
        flex-direction: column;
    }
    .modal-header,
    .modal-footer {
        padding: 15px;
        display: flex;
    }
    .modal-header {
        border-bottom: 1px solid #eeeeee;
        color: #4AAE9B;
        justify-content: space-between;
    }
    .modal-header .btn-close {
        padding: 0px;
        margin-top: 5px;
        margin-right: 15px;
    }
    .modal-header, .modal-footer {
        padding: 15px;
        display: flex;
    }
    span.content-header {
        line-height: 30px;
        color: #2EB085;
        font-size: 1.4rem;
        cursor: default;
        margin: 5px;
    }
    .modal-footer {
        border-top: 1px solid #eeeeee;
        justify-content: flex-end;
    }
    .modal-body {
        position: relative;
        padding: 20px 10px;
        overflow: auto;
    }
    .btn-close {
        border: none;
        font-size: 20px;
        padding: 20px;
        cursor: pointer;
        font-weight: bold;
        color: #4AAE9B;
        background: transparent;
    }
    button.btn-close:focus {
        outline: none;
    }
    .btn-green {
        color: white;
        background: #4AAE9B;
        border: 1px solid #4AAE9B;
        border-radius: 2px;
    }
    #create-edit-modal {
        width: 280px;
        height: auto; 
        position: unset;
        max-height: 95%;
    }
    #create-edit-modal.guest-width {
        width: 350px;
    }
    #create-edit-modal.migrate-width {
        width: 400px;
    }
    #create-edit-modal .modal-body {
        padding: 20px 40px;
    }
    #create-edit-modal .modal-body.wallet-padd {
        padding: 20px 20px;
    }
    #create-edit-modal input {
        max-width: 200px;
    }
    #create-edit-modal input.migrate {
        max-width: 340px;
        font-size: 0.9rem;
    }
    #create-edit-modal input.migrate:read-only {
        pointer-events: none;
    }
    #create-edit-modal.guest-width input {
        max-width: 270px;
    }
    #create-edit-modal.guest-width .modal-body.wallet-padd input {
        max-width: 300px;
    }
    span.lbl-content {
        position: relative;
        top: -15px;
        left: 15px;
        color: #62778a;
    }
    .wrap-active-checkbox {
        margin-top: 10px;
        margin-bottom: 20px;
    }
    div#create-edit-modal .modal-body input {
        margin-bottom: 20px;
    }
    #wallet_type {
        -moz-appearance:none; /* Firefox */
        -webkit-appearance:none; /* Safari and Chrome */
        appearance:none;
        color: #8b9dad;
    }
    select#wallet_type option:disabled {
        color: #ccc;
    }
    .wrap-select {
        display: flex;
        align-items: center;
    }
    .wrap-select i {
        position: absolute;
        color: lightslategrey;
        font-size: 1.1rem;
        right: 50px;
    }
    .wrap-select.card {
        margin-right: 0px;
    }
    .wrap-select.card i {
        right: 20px;
        top: 20px;
    }
    p.data-error {
        display: none;
        margin-bottom: 0px;
        padding-left: 3px;
        padding-right: 3px;
        color: #760000;
    }
    .ic-tag.label {
        padding-right: 1.25rem;
    }
    .form-control[readonly] {
        background-color: white !important;
        cursor: default !important;
    }
    #guest_name_ {
        padding-right: 15px;
    }
    .checkbox-elment .content {
        position: relative;
        color: rgba(128,128,128,.85);
        top: -15px;
        left: 15px;
    }
    section.modal-body p.grey-clr.guest i {
        margin-right: 10px;
    }
    section.modal-body p.grey-clr.guest {
        color: rgba(128,128,128,.85);
        margin-bottom: 30px;
    }
    p.grey-clr.guest.less-bottom {
        margin-bottom: 15px !important;
        margin-top: 5px !important;
    }
    div.wrap-searcher {
        display: flex;
        flex-direction: row;
        margin-bottom: 33px;
    }
    div.wrap-searcher input {
        margin-bottom: 0px !important;
        padding-right: 2.2rem;
    }   
    div.wrap-searcher i {
        position: absolute;
        z-index: 10;
        right: 55px;
        cursor: pointer;
        margin-top: 20px;
    }
    #search_guest_::-webkit-calendar-picker-indicator, #search_cluster_::-webkit-calendar-picker-indicator {
        display: none !important;
    }
    #loader_guest, #loader_cluster, #loader_cust {
        display: none;
    }
    #guest_name_[readonly], #guest_email_[readonly], #guest_phone_[readonly] {
        background: #ededed !important;
    }
    p.grey-clr.clear {
        display: none;
        cursor: pointer;
        margin-top: -20px;
        text-align: right;
        margin-bottom: 0px !important;
        color: rgba(128,128,128,.85) !important;
    }
    p.grey-clr.clear.title {
        color: rgba(128,128,128,.99);
    }
    p.grey-clr.clear:hover {
        color: #495057 !important;
        text-decoration: underline;
    }
    p.grey-clr.guest.nobott {
        margin-bottom: 5px !important;
        padding-left: 5px;
    }
    p.grey-clr.guest.nobott.migrate {
        color: #343434;
    }
    .space-top {
        margin-top: 50px;
    }
    .title-content {
        color: rgba(52,52,52,.8) !important;
    }
    .data-p {
        white-space: nowrap;
        font-size: 0.835rem;
        padding-left: 5px;
    }
    input.wallet {
        border: 0px;
        padding: 0px;
        border-radius: 0px;
        border-bottom: 1px solid rgba(139,157,173,.6);        
        min-height: unset !important;
        height: 40px !important;
        width: 90%;
        margin-left: 5px;
    }
    input.wallet:focus {
        outline: none;
        box-shadow: none;
    }
    .grey-clr.cluster {
        text-align: justify;
        word-spacing: -2px;
    }
    .loader-cluster-info {
        display: flex;
        position: absolute;
        align-items: center;
        justify-content: center;
        width: calc(100% - 40px);
        height: calc(100% - 40px);
        background-color: rgba(255,255,255,.6);
    }
    .ic-tag {
        color: #8d9eae !important;
        border: 1px solid #ABB7C3;
        margin-right: 5px;
        margin-bottom: 5px;
        height: 35px;
        min-height: unset;
    }
    .ic-tag button.btn {
        border: 1px solid #9faebd;
    }
    .wrap-cluster {
        max-height: 50vh;
        overflow: auto;
    }
    p.grey-clr.guest.space-top.no-bott {
        margin-bottom: 15px;
    }
    .chkbox-lbl {
        color: rgba(128,128,128,.85) !important;
        position: relative;
        left: 10px;
        top: -15px;
    }
    .loader-card-info {
        z-index: 1;
        display: flex;
        position: absolute;
        align-items: center;
        justify-content: center;
        width: calc(100% - 40px);
        margin-left: -20px;
        height: calc(100% - 40px);
        background-color: rgba(255,255,255,.6);
    }
    #create-edit-modal div#loader-cards {
        display: flex;
        padding-top: 140px;
        position: absolute;
        justify-content: center;
        width: calc(100% - 80px);
        height: calc(100% - 20px);
        background-color: rgba(255,255,255,1);
        background-color: rgba(255,255,255,.5);
    }
    div.wrap-searcher {
        margin-bottom: 0px;
        margin-top: 10px;
    }
    #datalist_modal div.wrap-searcher i {
        right: 33px;
    }
    #search_cust_::-webkit-calendar-picker-indicator {
        display: none !important;
    }
    div.wrap-searcher.migrate i {
        left: 330px;
        margin-top: 20px;
    }

    @media screen and (max-width: 500px) {
        span.content-header {
            font-size: 1.18rem;
        }
    }

     @media screen and (max-width: 430px) {
        #create-edit-modal.migrate-width {
            width: 90%;
        }
    }

    @media screen and (max-width: 375px) {
        #create-edit-modal.guest-width {
            width: 90%;
        }
    }

    #search_cluster_.err-shake, #search_cust_.err-shake  {
        /* Start the shake animation and make the animation last for 0.5 seconds */
        animation: shake 0.5s;

        /* When the animation is finished, start again */
        animation-iteration-count: infinite;
    }

    @keyframes shake {
        0% { transform: translate(1px, 1px) rotate(0deg); }
        10% { transform: translate(-1px, -2px) rotate(-1deg); }
        20% { transform: translate(-3px, 0px) rotate(1deg); }
        30% { transform: translate(3px, 2px) rotate(0deg); }
        40% { transform: translate(1px, -1px) rotate(1deg); }
        50% { transform: translate(-1px, 2px) rotate(-1deg); }
        60% { transform: translate(-3px, 1px) rotate(0deg); }
        70% { transform: translate(3px, 1px) rotate(-1deg); }
        80% { transform: translate(-1px, -1px) rotate(1deg); }
        90% { transform: translate(1px, 2px) rotate(0deg); }
        100% { transform: translate(1px, -2px) rotate(-1deg); }
    }
</style>