export function httpGetAsync(theUrl, callback, method, data) {
    try {
        let xhr = new XMLHttpRequest();
        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4 && xhr.status === 200) {
                if (callback) { //post might not have a callback
                    callback(xhr.responseText);
                }
            } else if (xhr.status === 302) {
                // either got redirected or try to post/put to a bad path
                console.log("httpGetAsync was redirected from url " + theUrl + " with method " + method);
            } else if (xhr.readyState === 4) {
                console.log("httpGetAsync Error to " + theUrl + " with data: " + data + " and  method: " + method);
                if (callback) {
                    console.log(xhr);
                    if (xhr.status === 0) {
                        callback(JSON.stringify({
                            'status': 'error',
                            'error': 'Iconic encountered an error due to a Cert or Network issue. ' +
                                'This is likely due to a Chrome cert issue where you need to re-accept the self-signed certificate. Please refresh.'
                        }));
                    } else {
                        callback(JSON.stringify({
                            'status': 'error',
                            'error': 'Iconic encountered an error: ' + xhr.status + ": " + xhr.statusText
                        }));
                    }
                } else {
                    alert("HTTP Browser error: " + xhr.statusText);
                }
            }
        };
        //xmlHttp.withCredentials = true;
        xhr.open(method, theUrl, true); // true for asynchronous
        xhr.setRequestHeader("accept", "application/json");
        xhr.setRequestHeader("content-type", "null");
        xhr.setRequestHeader("Authorization", "Bearer " + localStorage.getItem("access_token"));
        xhr.send(JSON.stringify(data));
    } catch (error) {
        alert("HTTP Browser error: " + error.toString());
    }
}

/* - - - */

export function getLanguage(array) { 
    switch(localStorage.getItem("lang")){ 
        case "en_US": return array.translations.en_US;
        case "it_IT": return array.translations.it_IT;
        case "fr_FR": return array.translations.fr_FR;

        default: return array.translations.en_US;
    }
}

/* - - - */

export function playNotification() {
    let audio = require.context('../assets/', false, /\.mp3$|\.mp4$/);
    let path = process.env.VUE_APP_SITEROOT + audio("./notification.mp3");
    let notification = new Audio(path); 
    notification.play();
}

/* - - - */

export function unlockAudio() {
    let audio = require.context('../assets/', false, /\.mp3$|\.mp4$/);
    let path = process.env.VUE_APP_SITEROOT + audio("./notification.mp3");
    const sound = new Audio(path);

    var playPromise = sound.play();
 
    if (playPromise !== undefined) {
        playPromise.then(()=> {
            sound.pause();
        })
        .catch(error => {
            console.error(error);
        });
    }
    sound.currentTime = 0;

    document.body.removeEventListener('click', unlockAudio);
    document.body.removeEventListener('touchstart', unlockAudio);
}

/* - - - */

export function removeInArray(arr) {
    var what, a = arguments, L = a.length, ax;
    while (L > 1 && arr.length) {
        what = a[--L];
        while ((ax= arr.indexOf(what)) !== -1) {
            arr.splice(ax, 1);
        }
    }
    return arr;
}

/* - - - */

var context = null;
var yodelBuffer = void 0;
//console.log(yodelBuffer);
export function prepareToTestNotification() {
    let audio = require.context('../assets/', false, /\.mp3$/)
    let audio_url = process.env.VUE_APP_SITEROOT + audio("./notification.mp3").toString(); 

    var AudioContext = window.AudioContext || window.webkitAudioContext;
    context = new AudioContext(); // Make it crossbrowser
    var gainNode = context.createGain();
    gainNode.gain.value = 1; // set volume to 100%
   
    // The Promise-based syntax for BaseAudioContext.decodeAudioData() is not supported in Safari(Webkit).
    try{
        window.fetch(audio_url)
        .then(response => response.arrayBuffer())
        .then(arrayBuffer => context.decodeAudioData(arrayBuffer,
        audioBuffer => {
            yodelBuffer = audioBuffer; 
        },
        error =>
            console.error(error)
        ));
    }catch(Exception){
        console.log(Exception);
    }
}

export function playTestNotification() {  
    console.log(yodelBuffer);
    var source = context.createBufferSource();
    source.buffer = yodelBuffer;
    source.connect(context.destination);
    source.start();
}

/* - - - */

export function getTodayDate() {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();

    return yyyy+ '-' + mm + '-' + dd;
}

/* - - - */

export const slugify = str =>
str
  .toLowerCase()
  .trim()
  .replace(/[^\w\s-]/g, '')
  .replace(/[\s_-]+/g, '-')
  .replace(/^-+|-+$/g, '');

/* - - - */

export function sortByProperty(property){  
    return function(a,b){  
       if(a[property] > b[property])  
          return 1;  
       else if(a[property] < b[property])  
          return -1;  
   
       return 0;  
    }  
}

/* - - - */

export function nl2br (str, is_xhtml) {
    if (typeof str === 'undefined' || str === null) {
        return '';
    }
    var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
}