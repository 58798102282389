<template>
    <select :name="nameVal" :id="idVal" :class="classVal" @change="onChangeSelect">

        <template v-if="showDefault == 'true'">
            <option selected disabled>{{ lang.select_option }}</option>
        </template>

        <template  v-for="(el,i) in data" :key="i">
            <template v-if="val === el.value.toString()">                
                <option :value="el.value" :disabled="checkForDisabled(el.value)" selected>{{ el.label }}</option>
            </template>
            <template v-else>
                <option :value="el.value" :disabled="checkForDisabled(el.value)">{{ el.label }}</option>
            </template>
        </template>
    </select>
</template>

<script>
    import * as Utility from '../../js/utility.js'
    import { useGettext } from '@jshmrtn/vue3-gettext'

    export default {
        name: 'DropDownList',
        props: {
            data: [],
            val: String,
            idVal: String,
            nameVal: String,
            classVal: String,
            showDefault: String,
            //changeFunction: String,
            useChangeFunction: String,
        },
        data() {
            return {
                lang: ""
            }
        },
        setup() { 
            const language = useGettext();
            return {
               language,
            };
        },
        mounted() {  
            this.lang = Utility.getLanguage(this.language);
        },
        methods: {
            onChangeSelect() {  
                if(this.useChangeFunction == 'true'){   
                    this.onChangeFunction();     
                }
            },
            onChangeFunction() {
                this.$emit('change-function');
            },
            checkForDisabled(val) { 
                if(val.toString().includes('disabled')){
                    return true;
                }else{
                    return false;
                }
            }
        }
    }
</script>

<style>
    .iconic-select:focus {
        outline: none;
        box-shadow: unset;
    }
    .iconic-select {
        min-height: unset;
        border-top: 0px;
        border-left: 0px;
        border-right: 0px;
        border-radius: unset;
        border-color: #8b9dad;
        background-color: unset;
        text-align: center;
        font-size: 0.9rem;
        font-weight: 100;
        color: grey;
        padding: 5px; 
        width: 90%;
    }
    .iconic-select.is-invalid {
        /* color: #dc3545; */
        border-bottom: 1px solid #dc3545;
    }
    .iconic-select option {
        text-align: left;
    }
    .iconic-select.no-bord {
        border-bottom: 0px;
        cursor: pointer;
    }
    .iconic-select.no-bord:active, .iconic-select.no-bord:hover {
        border-bottom: 1px solid #8b9dad;
    }
</style>