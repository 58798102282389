<template>
    <div :id="idVal" :class="classVal">
        <div class="spinner-border ic-spinner ic-spinner-green" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Loader',
        props: {
            idVal: String,
            classVal: String
        }
    }
</script>

<style>
    .ic-spinner-green {
        border: 1px solid #2eb085 !important;
        border-right-color: transparent !important;
    }

    @keyframes spinner-border {
        to { transform: rotate(360deg); }
    }

    .spinner-border {
        display: inline-block;
        width: $spinner-width;
        height: $spinner-height;
        vertical-align: text-bottom;
        border: $spinner-border-width solid currentColor;
        border-right-color: transparent;
        border-radius: 50%;
        animation: spinner-border .75s linear infinite;
    }

    .spinner-border-sm {
        width: $spinner-width-sm;
        height: $spinner-height-sm;
        border-width: $spinner-border-width-sm;
    }

    @keyframes spinner-grow {
        0% {
            transform: scale(0);
        }
        50% {
            opacity: 1;
            transform: none;
        }
    }

    .spinner-grow {
        display: inline-block;
        width: $spinner-width;
        height: $spinner-height;
        vertical-align: text-bottom;
        background-color: currentColor;
        border-radius: 50%;
        opacity: 0;
        animation: spinner-grow .75s linear infinite;
    }

    .spinner-grow-sm {
        width: $spinner-width-sm;
        height: $spinner-height-sm;
    }
</style>