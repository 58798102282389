<template>
    <transition name="modal-fade">
        <div class="modal-backdrop">
            <div id="alert-modal" class="modal opacity-class" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription">
                <header class="modal-header">
                    <slot name="header">
                        <span class="content-header">{{ title }}</span>

                        <button type="button" class="btn-close" @click="close" aria-label="Close modal">x</button>
                    </slot>
                </header>
                <section class="modal-body">
                    <slot name="body">
                        <span v-html="content"></span>
                    </slot>
                </section>
                <footer class="modal-footer">
                    <slot name="footer">
                        <button v-if="continue_btn" type="button" class="btn ic-btn-small ic-btn-green" @click="continueAction">{{ lang.continue }}</button>
                        <button type="button" id="close-alert" class="btn ic-btn-small ic-btn-green" @click="close" aria-label="Close modal">{{ lang.close }}</button>
                    </slot>
                </footer>
            </div>
        </div>
    </transition>
</template>

<script>
    import * as Utility from '../../js/utility.js'
    import { useGettext } from '@jshmrtn/vue3-gettext'

    export default {
        name: 'AlertModal',
        props: {
            title: String,
            content: String,
            continue_action: String,
            continue_btn: Boolean
        },
        setup() { 
            const language = useGettext();
            return {
               language,
            };
        },
        mounted() {  
            this.lang = Utility.getLanguage(this.language);
        },
        data() {
            return {
                lang: ""
            }
        },
        methods: {
            close() {
                this.$emit('close');
            },
            continueAction() {
                this.continue_action();
            }
        }
    }
</script>

<style>
    .modal-fade-enter, .modal-fade-enter-active,
    .modal-fade-leave-active {
        opacity: 0;
    }
    .modal-fade-enter-to, .modal-fade-leave {
        opacity: 1;
    }

    .modal-fade-enter-active,
    .modal-fade-leave-active {
        transition: opacity .5s ease
    }
    .modal-backdrop {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .modal {
        background: #FFFFFF;
        box-shadow: 2px 2px 20px 1px;
        overflow-x: auto;
        display: flex;
        flex-direction: column;
    }
    .modal-header,
    .modal-footer {
        padding: 15px;
        display: flex;
    }
    .modal-header {
        border-bottom: 1px solid #eeeeee;
        color: #4AAE9B;
        justify-content: space-between;
    }
    .modal-footer {
        border-top: 1px solid #eeeeee;
        justify-content: flex-end;
    }
    .modal-body {
        position: relative;
        padding: 20px 10px;
        overflow: auto;
    }
    .btn-close {
        border: none;
        font-size: 20px;
        padding: 20px;
        cursor: pointer;
        font-weight: bold;
        color: #4AAE9B;
        background: transparent;
    }
    .btn-green {
        color: white;
        background: #4AAE9B;
        border: 1px solid #4AAE9B;
        border-radius: 2px;
    }
    #alert-modal {
        width: auto;
        height: auto; 
        position: unset;
        max-width: 490px;
    }
    #alert-modal .modal-body {
        padding: 25px;
    }
    #alert-modal span.content-header {
        left: 10px;
        font-size: 1.1rem !important;
        margin: 5px;
        margin-right: 40px;
    }

    #alert-modal .modal-body span {
        color: #6c757d;
        text-align: justify;
    }

    @media screen and (max-width: 500px) {
        #alert-modal {
            width: 85%;
        }
    }
</style>