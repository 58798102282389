<template>
    <transition name="modal-fade">
        <div class="modal-backdrop">
            <div id="channel_list_modal" class="modal opacity-class" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription">
                <header class="modal-header">
                    <slot name="header">
                        <span class="content-header">{{ modal_title }}</span>

                        <button type="button" class="btn-close" @click="close_modal" aria-label="Close modal">x</button>
                    </slot>
                </header>
                <section class="modal-body">
                    <slot name="body">
                        <template v-if="channels.length == 0">
                            <div class='alert-msg'>
                                {{ max_add_reach }}
                            </div>
                        </template>
                        <template v-else>
                            <DropDownList idVal="channel_list" :nameVal="input_name" showDefault="false" classVal="iconic-select channel-select" val="" :data="channels" />
                        </template>
                    </slot>
                </section>
                <footer class="modal-footer">
                    <slot name="footer">
                        <template v-if="channels.length > 0">
                            <button type="button" id="close-alert" class="btn ic-btn-small ic-btn-green" @click="setChannelFunction" aria-label="Close modal">{{ lang.save }}</button>
                        </template>
                        <button type="button" id="close-alert" class="btn ic-btn-small ic-btn-green" @click="close_modal" aria-label="Close modal">{{ lang.close }}</button>
                    </slot>
                </footer>
            </div>
        </div>
    </transition>
</template>

<script>
    import * as Utility from './../js/utility.js'
    import { useGettext } from '@jshmrtn/vue3-gettext'

    import DropDownList from './elements/DropDownList'

    export default {
        name: 'ChannelListModal',
        components: {
            DropDownList
        },
        props: {
            setChannelFunction: String,
            max_add_reach: String,
            close_modal: String,
            modal_title: String,
            input_name: String,
            channels: [],
        },
        setup() { 
            const language = useGettext();
            return {
               language,
            };
        },
        mounted() {  
            this.lang = Utility.getLanguage(this.language);
        },
        data() {
            return {
                lang: ""
            }
        }
    }
</script>

<style>
    .modal-fade-enter, .modal-fade-enter-active,
    .modal-fade-leave-active {
        opacity: 0;
    }
    .modal-fade-enter-to, .modal-fade-leave {
        opacity: 1;
    }

    .modal-fade-enter-active,
    .modal-fade-leave-active {
        transition: opacity .5s ease
    }
    .modal-backdrop {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .modal {
        background: #FFFFFF;
        box-shadow: 2px 2px 20px 1px;
        overflow-x: auto;
        display: flex;
        flex-direction: column;
    }
    .modal-header,
    .modal-footer {
        padding: 15px;
        display: flex;
    }
    .modal-header {
        border-bottom: 1px solid #eeeeee;
        color: #4AAE9B;
        justify-content: space-between;
    }
    .modal-header .btn-close {
        padding: 0px;
        margin-top: 5px;
        margin-right: 15px;
    }
    .modal-header, .modal-footer {
        padding: 15px;
        display: flex;
    }
    span.content-header {
        line-height: 30px;
        color: #2EB085;
        font-size: 1.4rem;
        cursor: default;
        margin: 5px;
    }
    .modal-footer {
        border-top: 1px solid #eeeeee;
        justify-content: flex-end;
    }
    .modal-body {
        position: relative;
        padding: 20px 10px;
        overflow: auto;
    }
    .btn-close {
        border: none;
        font-size: 20px;
        padding: 20px;
        cursor: pointer;
        font-weight: bold;
        color: #4AAE9B;
        background: transparent;
    }
    button.btn-close:focus {
        outline: none;
    }
    .btn-green {
        color: white;
        background: #4AAE9B;
        border: 1px solid #4AAE9B;
        border-radius: 2px;
    }

    @media screen and (max-width: 500px) {
        span.content-header {
            font-size: 1.18rem;
        }
    }

    .channel-select {
        width: 80%;
        margin-left: 8%;
        margin-top: 15px;
    }
    .alert-msg {
        color: #959595 !important;
        width: calc(100% - 40px);
        height: calc(100% - 40px);
        margin-left: 20px;
        margin-top: 20px;
    }
    #channel_list_modal {
        width: 350px;
        height: 247px; 
        position: unset;        
        transition: width 1s, height 1s;
    }

    @media screen and (max-width: 400px) {
        #channel_list_modal {
            width: 90%;
        }
    }
</style>