<template>
    <div class='shop-lateral-list'>
        <div class="sidebar-sticky pt-3">
            <template v-if="isLoading" >

                <div v-if="content == 'services'" class='new_srv'>
                    <p class="title">{{ lang.services }}</p>

                    <ul class="nav flex-column">
                        <li class="nav-item">
                            <a id='c_service' @click="$parent.setNewService()" :class="'nav-link '+checkSelected()">
                                <table class="layout-arrow-show">
                                    <tr>
                                        <td><i class="ri-add-circle-line icon-sidebar create"></i></td>
                                        <td>{{ lang.create_service }}</td>
                                    </tr>
                                </table>
                            </a>
                        </li>
                    </ul>
                    <br/>
                </div>

                <p class="title">{{ title }}</p>

                <div :class="'contain-shop-list '+getContentClass()">
                    <ul class="nav flex-column">
                        <template v-for="(shop,c) in list_shops.shops" :key="c">
                            
                            <li class="nav-item" v-if="shop.name.trim() != ''">
                                <template v-if="selected == shop.name">
                                    <a class="nav-link selected">
                                        <table class="layout-arrow-show">
                                            <tr>
                                                <td><i class="ri-arrow-drop-right-line icon-sidebar"></i></td>
                                                <td>{{ shop.name }}</td>
                                            </tr>
                                        </table>
                                    </a>
                                </template>
                                <template v-else>
                                    <a class="nav-link" @click="changeShop(shop.name, shop.id)">
                                        <table class="layout-arrow-show">
                                            <tr>
                                                <td><i class="ri-arrow-drop-right-line icon-sidebar"></i></td>
                                                <td>{{ shop.name }}</td>
                                            </tr>
                                        </table>
                                    </a>
                                </template> 
                            </li>

                        </template>
                    </ul>
                </div>
            </template>
        </div>
    </div> 
</template>

<script>
    import * as Utility from '../js/utility.js'
    import { useGettext } from '@jshmrtn/vue3-gettext'

    export default {
        name: 'ShopLateralList',
        setup() { 
            const language = useGettext();
            return {
               language,
            };
        },
        mounted() { 
            this.lang = Utility.getLanguage(this.language);
        },
        props: {
            changeShopFunction: String,
            selected: String,
            content: String,
            title: String,
            list_shops: []
        },
        data() {
            return {
                isLoading: false
            }
        },
        methods: {
            changeShop(shop_name, shop_id) { 
                this.changeShopFunction(shop_name, shop_id);
            },
            startRender() {
                this.isLoading = true;
            },
            getContentClass() {
                if(this.content == 'services'){
                    return 'space-large';
                }else{
                    return '';
                }
            },
            checkSelected() { 
                if(this.selected.trim() == this.lang.new_service.trim()){
                    return 'selected';
                }else{
                    return '';
                }
            }
        }
    }
</script>

<style>
    .shop-lateral-list {
        border-width: 1px 1px 1px 0px;
        border-style: solid;
        border-color: lightgrey;
        background-color: rgba(233,236,239,.5);
        width: 250px;
        margin-left: -25px;
        margin-top: 6px;
        height: 100%;
    }
    .shop-lateral-list li {
        cursor: pointer;
    }
    .shop-lateral-list p.title {
        margin-top: 3px;
        margin-left: 15px;
        color: rgba(52,52,52,.7);
        position: relative;
        font-size: 1rem;
        opacity: 0.88;
        top: 8px;
    }
    .shop-lateral-list li a.nav-link.selected {
        color: #2EB085 !important;
    }
    .shop-lateral-list li a.nav-link.selected:hover {
        color: #248767 !important;
    }
    .contain-shop-list {
        height: calc(100% - 50px);
        overflow: auto;
    }
    .contain-shop-list.space-large {
        height: calc(100% - 150px);
    }
    table.layout-arrow-show {
        table-layout: fixed;
    }
    .ri-arrow-drop-right-line.icon-sidebar {
        position: unset;
        top: unset;
    }
    .icon-sidebar.create {
        font-size: 15px;
        top: 2px;
    }
</style>