<template>
    <img :src="resolve_img_url(srcVal)" :id="idVal" :class="classVal" :title="titleVal" />
</template>

<script>
    export default {
        name: 'Image',
        props: {
            classVal: String,
            titleVal: String,
            srcVal: String,
            idVal: String
        },
        methods: {
            resolve_img_url: function (path) {
                let images = require.context('../../assets/', false, /\.png$|\.jpg$|\.gif$/)
                return images("./"+path)
            }
        }
    }
</script>

<style>
</style>
