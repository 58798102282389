<template>
    <nav id='header-pages' class="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
        <div class='contain-title-header'>
            <Image srcVal="Iconic-logo.png" classVal="header-image" idVal="" titleVal="Iconic" />
            <a class="navbar-brand col-md-3 col-lg-2 mr-0 px-3" href="#">Store Manager Dashboard</a>
        </div>
        <button id="opn-menu" class="navbar-toggler position-absolute d-md-none collapsed" type="button" v-on:click="checkToEspandNavbar()" data-toggle="collapse" data-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        
        <ul class="navbar-nav px-3 logout-btn">
            <li class="nav-item text-nowrap">
            <a class="nav-link" :href="getUrlLogout()">{{ lang.sign_out }}</a>
            </li>
        </ul>
    </nav>
</template>

<script>
    import $ from 'jquery'
    import Image from './elements/Image.vue'
    import * as Utility from '../js/utility.js'
    import { useGettext } from '@jshmrtn/vue3-gettext'

    export default {
        name: 'HeaderPages',
        setup() { 
            const language = useGettext();
            return {
               language,
            };
        },
        mounted() {  
            this.lang = Utility.getLanguage(this.language);
        },
        components: {
            Image
        },
        data(){
          return{
              lang: "",
              siteroot: process.env.VUE_APP_SITEROOT 
          }
        },
        methods: {
            checkToEspandNavbar() {

                $("#opn-menu").blur();
                setTimeout(function(){
                    if($("#sidebarMenu").hasClass("show")){
                        $("#sidebarMenu").removeClass("show");
                    }else{
                        window.scrollTo(0, 0);
                        $("#sidebarMenu").addClass("show");
                    }
                }, 300);
            },
            getUrlLogout(){
                return this.siteroot + localStorage.getItem('url_part');
            }
        },
    }
</script>

<style>
    #header-pages {
        position: fixed;
        width: 100%;
    }
    .navbar-brand {
        padding-top: .75rem;
        padding-bottom: .75rem;
        font-size: 1rem;
    }
    .header-image {
        height: 20px;
        margin-left: 15px;
    }
    button.navbar-toggler.position-absolute.d-md-none {
        right: 10px;
    }
    .logout-btn li a.nav-link:hover {
        color: rgba(255,255,255,.5) !important;
        text-decoration: underline;
    }

    @media screen and (max-width: 767px) {
        .contain-title-header {
            display: flex;
        }
        .contain-title-header img {
            margin-top: 13px;
        }
        .logout-btn {
            display: none;
        }
    }
</style>
